<template>
  <!-- 订阅选择方案 -->
  <div id="choosePlan">
    <div class="banner">
      <img src="@/assets/img/subscribe/orderBanner.png" alt="" />
    </div>
    <div class="order">
      <div class="orderArea">
        <div class="order-title">{{ isUpdate ? " " : "订单支付中心" }}</div>
        <div class="container">
          <div class="container-title">
            {{ isUpdate ? "会员升级计划" : "LeetCode千题视频讲解会员订阅" }}
          </div>
          <div class="container-subtitle">
            <span class="addWechat"
              >添加微信？<el-popover
                placement="bottom"
                trigger="hover"
                popper-class="popover-menu"
              >
                <div style="padding: 18px; text-align: center">
                  <img
                    style="
                      width: 170px;
                      height: 170px;
                      vertical-align: top;
                      margin-bottom: 6px;
                    "
                    :src="qrcode && qrcode.imageUrl"
                    alt=""
                  />
                  <div
                    style="
                      text-align: center;
                      font-size: 12px;
                      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                      font-weight: 400;
                      color: #fa6400;
                      line-height: 16px;
                    "
                  >
                    扫描微信二维码<br />课程和网站疑问
                  </div>
                </div>

                <span slot="reference" class="openQrcode">点我</span>
              </el-popover>
            </span>
          </div>
          <div class="main-plan">
            <div class="title">
              方案选择<span class="tip">
                {{
                  isUpdate
                    ? "* 本月订阅结束前10天，方可升级；从购买日期开始，升级之后次月生效"
                    : "* 每月自动续费，可随时取消。首月+$20，后续按下方价格收取"
                }}
              </span>
            </div>
            <div class="plans">
              <div
                :class="['plan', planType == plan.id ? 'active' : '']"
                v-for="(plan, index) in plans"
                :key="index"
                @click="choosePlan(plan)"
              >
                <div class="plan-title">{{ plan.name }}</div>
                <div class="plan-price">
                  $<span>{{ plan.price }}</span
                  >/月
                </div>
                <img
                  class="choosed"
                  src="@/assets/img/subscribe/gou.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="sub-plan">
            <div class="title">其他服务</div>
            <div class="plans">
              <el-popover
                placement="top"
                trigger="hover"
                popper-class="popover-menu"
                v-if="!hiddenCategory"
              >
                <div style="padding: 18px; text-align: center; color: #e59f3c">
                  购买后，不取消订阅即可永久获得
                  <br />
                  若取消订阅后第二次购买，则需重新购买
                </div>
                <div
                  slot="reference"
                  :class="[
                    'plan',
                    hasCategory ? 'active' : '',
                    planType == 1 ? 'forbid' : '',
                  ]"
                  @click="chooseCategory"
                >
                  <div class="plan-title">LeetCode分类顺序表</div>
                  <div class="plan-price">
                    $<span>{{ activePlan.priceCategory }}</span>
                  </div>
                  <img
                    class="choosed"
                    src="@/assets/img/subscribe/gou.png"
                    alt=""
                  />
                  <div class="tips">* 订阅期间永久有效</div>
                  <div class="hot">
                    <img src="@/assets/img/subscribe/hottip.png" alt="" />
                  </div>
                </div>
              </el-popover>

              <div
                :class="['plan', hasQa ? 'active' : '']"
                @click="
                  hasQa = !hasQa;
                  couponKey = '';
                  haveDiscount = false;
                  discountPrice = 0;
                  $forceUpdate();
                "
              >
                <div class="plan-title">微信群答疑</div>
                <div class="plan-price">
                  $<span>{{ activePlan.priceQa }}</span
                  >/月
                </div>
                <img
                  class="choosed"
                  src="@/assets/img/subscribe/gou.png"
                  alt=""
                />
                <div class="tips">* Edward老师亲自解答</div>
                <div class="hot">
                  <img src="@/assets/img/subscribe/hottip.png" alt="" />
                </div>
              </div>
              <div
                :class="['plan', hasCode ? 'active' : '']"
                @click="
                  hasCode = !hasCode;
                  couponKey = '';
                  haveDiscount = false;
                  discountPrice = 0;
                  $forceUpdate();
                "
              >
                <div class="plan-title">三种语言代码</div>
                <div class="plan-price">
                  $<span>{{ activePlan.priceCode }}</span
                  >/月
                </div>
                <img
                  class="choosed"
                  src="@/assets/img/subscribe/gou.png"
                  alt=""
                />
                <div class="tips">* 建议 C++、Python同学选择</div>
              </div>
              <div
                :class="['plan', hasIdea ? 'active' : '', 'forbid']"
                @click="
                  hasIdea = false;
                  couponKey = '';
                  haveDiscount = false;
                  discountPrice = 0;
                  $forceUpdate();
                "
              >
                <div class="plan-title">思路点评 &amp; 高频解析</div>
                <div class="plan-price">
                  $<span>{{ activePlan.priceIdea }}</span
                  >/月
                </div>
                <img
                  class="choosed"
                  src="@/assets/img/subscribe/gou.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div class="payment">
            <div
              v-if="!haveDiscount && !isUpdate"
              class="coupons"
              style="text-align: right; margin-top: 20px"
            >
              <el-form label-width="100px" style="display: inline-block">
                <el-form-item label="优惠码">
                  <el-input
                    v-model="couponKey"
                    style="width: 300px; margin-right: 12px"
                  ></el-input>
                  <el-button type="danger" @click="getDiscountPrice"
                    >立即兑换</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div class="title">
              支付方式<img src="@/assets/img/subscribe/paypal.png" /><span
                class="tip"
              >
                *支持 Credit Card 、Debit Card 付款</span
              >
              <br
                v-if="
                  isUpdate &&
                  subscriptionDetail &&
                  !subscriptionDetail.hasCategory &&
                  hasCategory
                "
              />
              <span
                class="categoryTips"
                v-if="
                  isUpdate &&
                  subscriptionDetail &&
                  !subscriptionDetail.hasCategory &&
                  hasCategory
                "
              >
                如选择《分类顺序表》，需立即付款，付款后刷新课程立即生效；其他选项升级后下月自动扣费
              </span>
              <el-checkbox v-model="userAgreement" style="float: right"
                >同意<router-link to="/question?type=10" class="href"
                  >《自动续费协议》</router-link
                >和<router-link to="/question?type=8" class="href"
                  >《版权保护协议》</router-link
                ></el-checkbox
              >
            </div>
            <div v-if="haveDiscount" class="amount">
              优惠金额:
              <span class="totalCount">${{ discountPrice }}</span>
            </div>
            <div class="amount">
              {{ isUpdate ? "下月" : "" }}应付金额:
              <span class="totalCount">${{ totalCount }}</span>
              <span class="tip"
                >（原价+手续费{{ isUpdate ? "" : "+首月$20" }}）</span
              >
            </div>

            <div
              class="amount"
              v-if="
                isUpdate &&
                subscriptionDetail &&
                !subscriptionDetail.hasCategory &&
                hasCategory
              "
            >
              分类顺序表应付金额:
              <span class="totalCount">${{ categoryPrice }}</span>
              <span class="tip">（原价+手续费）</span>
            </div>
            <div class="buyButton">
              <el-button class="buy" @click="toBuy" v-if="!isUpdate"
                >立即支付</el-button
              >
              <el-button
                class="buy"
                @click="updateSubscription(true)"
                v-else-if="
                  isUpdate && (subscriptionDetail.hasCategory || !hasCategory)
                "
                >立即升级</el-button
              >
              <el-button
                class="buy"
                @click="buyCategory"
                v-else-if="
                  isUpdate &&
                  subscriptionDetail &&
                  !subscriptionDetail.hasCategory &&
                  hasCategory
                "
                >立即支付</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="paypalVisible"
      title="选择支付方式"
	   :close-on-click-modal="false"
      width="400px"
      top="30vh"
    >
      <div id="paypal-button-container" ref="paypal-button-container"></div>
    </el-dialog>
  </div>
</template>
<script>
import { loadScript } from "@paypal/paypal-js";
import {
  getPlanList,
  getPrices,
  getPlanId,
  saveSubscriptionId,
  getSubscriptionDetail,
  updateSubscription,
  createOrder,
  paypalPaymentCallback,
  checkPlan,
} from "@/service/subscribe";
import {
  getCourseBanner,
  getPaypalClientId,
  getCouponDiscountPrice,
} from "@/service";
export default {
  name: "choosePlan",
  data() {
    return {
      planType: 0,
      plans: [],
      otherPlanType: [],
      userAgreement: false,
      totalCount: 0,
      paypalVisible: false,
      hasCategory: false,
      hasQa: false,
      hasCode: false,
      hasIdea: false,
      activePlan: {},
      planId: "",
      qrcode: {},
      clientId: "",
      isUpdate: false,
      subscriptionDetail: {},
      hiddenCategory: false,
      categoryPrice: 0,
      couponKey: "",
      haveDiscount: false,
      discountPrice: 0,
    };
  },

  methods: {
    choosePlan(plan) {
      this.activePlan = plan;
      this.planType = plan.id;
      this.couponKey = "";
      this.haveDiscount = false;
      this.discountPrice = 0;
    },
    toBuy() {
      if (!this.userAgreement) {
        return this.$message.warning("请先同意相关协议");
      }

      this.paypalVisible = true;
      let that = this;
      if (window.paypal) {
        this.$nextTick(() => {
          window.paypal
            .Buttons({
              createSubscription: async (data, actions) => {
                const requestData = {
                  courseSubscriptionsLcId: that.planType,
                  hasCategory: that.hasCategory,
                  hasCode: that.hasCode,
                  hasQa: that.hasQa,
                  hasIdea: that.hasIdea,
                  code: that.couponKey,
                };
                let res = await getPlanId(requestData);
                that.planId = res.result;
                return actions.subscription.create({
                  plan_id: that.planId,
                });
              },
              onApprove: function (data, actions) {
                const requestData = {
                  planId: that.planId,
                  subscriptionsId: data.subscriptionID,
                  code: that.couponKey,
                };
                saveSubscriptionId(requestData).then((res) => {
                  if (res.success) {
                    that.$router.push("/pay/success");
                  }
                });
              },
            })
            .render("#paypal-button-container");
        });
      } else {
        this.$nextTick(() => {
          loadScript({
            "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
            vault: true,
          }).then((paypal) => {
            paypal
              .Buttons({
                createSubscription: async (data, actions) => {
                  const requestData = {
                    courseSubscriptionsLcId: that.planType,
                    hasCategory: that.hasCategory,
                    hasCode: that.hasCode,
                    hasQa: that.hasQa,
                    hasIdea: that.hasIdea,
                    code: that.couponKey,
                  };
                  let res = await getPlanId(requestData);
                  that.planId = res.result;
                  return actions.subscription.create({
                    plan_id: that.planId,
                  });
                },
                onApprove: function (data, actions) {
                  const requestData = {
                    planId: that.planId,
                    subscriptionsId: data.subscriptionID,
                    code: that.couponKey,
                  };
                  saveSubscriptionId(requestData).then((res) => {
                    if (res.success) {
                      that.$router.push("/pay/success");
                    }
                  });
                },
              })
              .render("#paypal-button-container");
          });
        });
      }
    },
    getPlanList() {
      getPlanList().then((res) => {
        if (res.success) {
          this.plans = res.result;
          this.activePlan = res.result[0];
          this.planType = res.result[0].id;
          if (!this.isUpdate) {
            this.getPrices();
          }
        }
      });
    },
    chooseCategory() {
      if (this.planType != 1) {
        this.hasCategory = !this.hasCategory;
      }
      this.couponKey = "";
      this.haveDiscount = false;
      this.discountPrice = 0;
    },
    getPrices() {
      const data = {
        courseSubscriptionsLcId: this.planType,
        hasCategory: this.hasCategory,
        hasCode: this.hasCode,
        hasQa: this.hasQa,
        hasIdea: this.hasIdea,
        isUpdate: this.isUpdate,
      };
      getPrices(data).then((res) => {
        if (res.success) {
          this.totalCount = res.result.price;
          this.categoryPrice = res.result.categoryPrice;
        }
      });
    },
    getSubscriptionDetail() {
      getSubscriptionDetail(this.$route.query.updateId).then((res) => {
        if (res.success) {
          this.planType = res.result.id;
          this.hasCode = res.result.hasCode;
          this.hasIdea = res.result.hasIdea;
          this.hasQa = res.result.hasQa;
          this.hasCategory = res.result.hasCategory;
          this.subscriptionDetail = res.result;
          this.activePlan = this.plans.filter((item) => {
            return item.id == res.result.id;
          })[0];
          if (res.result.hasCategory) {
            this.hiddenCategory = true;
          }
          this.getPrices();
        }
      });
    },
    updateSubscription(needCheck) {
      if (!this.userAgreement) {
        return this.$message.warning("请先同意相关协议");
      }
      if (needCheck) {
        const checkData = {
          userSubscriptionsId: Number(this.$route.query.updateId),
          courseSubscriptionsLcId: this.planType,
        };
        checkPlan(checkData).then((valid) => {
          if (valid.result) {
            const data = {
              userSubscriptionsId: Number(this.$route.query.updateId),
              courseSubscriptionsLcId: this.planType,
              hasCode: this.hasCode,
              hasQa: this.hasQa,
              hasIdea: this.hasIdea,
            };
            updateSubscription(data).then((res) => {
              if (res.success) {
                this.$message.success("升级计划成功！");
                this.$router.push("/center/subscription");
              }
            });
          } else {
            this.$message.error("订阅计划不能降级！");
          }
        });
      } else {
        const data = {
          userSubscriptionsId: Number(this.$route.query.updateId),
          courseSubscriptionsLcId: this.planType,
          hasCode: this.hasCode,
          hasQa: this.hasQa,
          hasIdea: this.hasIdea,
        };
        updateSubscription(data).then((res) => {
          if (res.success) {
            this.$message.success("升级计划成功！");
            this.$router.push("/center/subscription");
          }
        });
      }
    },
    buyCategory() {
      if (!this.userAgreement) {
        return this.$message.warning("请先同意相关协议");
      }
      this.paypalVisible = true;
      let that = this;
      const checkData = {
        userSubscriptionsId: Number(this.$route.query.updateId),
        courseSubscriptionsLcId: this.planType,
      };
      checkPlan(checkData).then((valid) => {
        if (valid.result) {
          if (window.paypal) {
            this.$nextTick(() => {
              window.paypal
                .Buttons({
                  createOrder: async function (data, actions) {
                    const orderData = {
                      userSubscriptionsId:
                        that.subscriptionDetail.userSubscriptionsId,
                      courseSubscriptionsLcId: that.planType,
                      createTime: that.dateFormat(
                        new Date(),
                        "yyyy-MM-dd hh:mm:ss"
                      ),
                      code: that.couponKey,
                    };
                    let res = await createOrder(orderData);
                    return res.result.content;
                  },
                  onApprove: async function (data, actions) {
                    return that.paypalCallback(data.orderID);
                  },
                })
                .render("#paypal-button-container");
            });
          } else {
            this.$nextTick(() => {
              loadScript({
                "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
              }).then((paypal) => {
                paypal
                  .Buttons({
                    createOrder: async function (data, actions) {
                      const orderData = {
                        userSubscriptionsId:
                          that.subscriptionDetail.userSubscriptionsId,
                        courseSubscriptionsLcId: that.planType,
                        createTime: that.dateFormat(
                          new Date(),
                          "yyyy-MM-dd hh:mm:ss"
                        ),
                      };
                      let res = await createOrder(orderData);
                      return res.result.content;
                    },
                    onApprove: async function (data, actions) {
                      return that.paypalCallback(data.orderID);
                    },
                  })
                  .render("#paypal-button-container");
              });
            });
          }
        } else {
          this.$message.error(valid.message);
        }
      });
    },
    dateFormat(thisDate, fmt) {
      var o = {
        "M+": thisDate.getMonth() + 1,
        "d+": thisDate.getDate(),
        "h+": thisDate.getHours(),
        "m+": thisDate.getMinutes(),
        "s+": thisDate.getSeconds(),
        "q+": Math.floor((thisDate.getMonth() + 3) / 3),
        S: thisDate.getMilliseconds(),
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (thisDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    async paypalCallback(orderID) {
      let res = await paypalPaymentCallback(orderID);
      if (res.success) {
        this.updateSubscription(false);
      } else {
        this.$message.error("订阅计划不能降级！");
      }
    },

    getDiscountPrice() {
      const data = {
        code: this.couponKey,
        courseSubscriptionsLcId: this.planType,
      };
      getCouponDiscountPrice(data).then((res) => {
        if (res.success) {
          this.haveDiscount = true;
          this.discountPrice = res.result;
          this.totalCount = this.totalCount - this.discountPrice;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
  mounted() {
    this.getPlanList();
    getCourseBanner(2).then((res) => {
      if (res.success) {
        this.qrcode = res.result[0];
      }
    });
    if (this.$route.query.updateId) {
      this.isUpdate = true;
      this.getSubscriptionDetail();
    } else {
      this.isUpdate = false;
    }
    // getPaypalClientId().then((res) => {
    //   if (res.success) {
    //     this.clientId = res.result;
    //   }
    // });
  },
  watch: {
    planType(val) {
      if (val == "1") {
        this.hasCategory = false;
      }
      this.getPrices();
    },
    hasCategory() {
      this.getPrices();
    },
    hasCode() {
      this.getPrices();
    },
    hasQa() {
      this.getPrices();
    },
    hasIdea() {
      this.getPrices();
    },
    $route() {
      if (this.$route.query.updateId) {
        this.isUpdate = true;
        this.getSubscriptionDetail();
      } else {
        this.isUpdate = false;
      }
    },
    paypalVisible(val) {
      if (!val) {
        this.$refs["paypal-button-container"].innerHTML = "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  img {
    width: 100%;
    vertical-align: top;
  }
}
.order {
  .orderArea {
    width: 1200px;
    margin: 0 auto;
    margin-top: -60px;
    transform: translateY(-280px);
    z-index: 9;
    .order-title {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 27px;
      margin-bottom: 18px;
    }
    .container {
      background: #fff;
      box-shadow: 0px 0px 39px 0px rgba(120, 120, 128, 0.16);
      border-radius: 8px;
      padding: 64px 100px;
      .container-title {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #e59f3c;
        line-height: 27px;
        margin-bottom: 14px;
        text-align: center;
      }
      .container-subtitle {
        width: 1000px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e59f3c;
        line-height: 21px;
        text-align: center;
        margin: 0 auto;
        position: relative;
        .addWechat {
          position: absolute;
          right: 0;
          top: 0;
          color: rgba(0, 0, 0, 0.5);
        }
        .openQrcode {
          text-decoration: underline;
          color: #e59f3c;
          cursor: pointer;
        }
      }
      .main-plan,
      .sub-plan {
        margin-top: 64px;
        .title {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          line-height: 24px;
          .tip {
            font-size: 12px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #e59f3c;
            line-height: 18px;
          }
        }
        .plans {
          margin-top: 18px;
          .plan {
            display: inline-block;
            margin-right: 38px;
            width: 210px;
            padding: 22px 0;
            border-radius: 4px;
            box-shadow: 0px 0px 39px 0px rgba(120, 120, 128, 0.16);
            text-align: center;
            cursor: pointer;
            position: relative;
            border: 1px solid transparent;
            .tips {
              font-size: 12px;
              font-family: SourceHanSansCN-Normal, SourceHanSansCN;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
              line-height: 18px;
              position: absolute;
              bottom: -30px;
              left: 0;
            }
            .hot {
              position: absolute;
              top: -10px;
              right: 0;
              img {
                width: 38px;
              }
            }
            .choosed {
              position: absolute;
              bottom: -1px;
              right: 0;
              vertical-align: top;
              display: none;
            }
            &.active {
              background: #f6f1e9;
              color: rgba(229, 159, 60, 1);
              border: 1px solid rgba(229, 159, 60, 1);
              .choosed {
                display: block;
              }
            }
            &.forbid {
              background: rgba(0, 0, 0, 0.1);
              cursor: not-allowed;
            }
            .plan-title {
              font-size: 14px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.4);
              line-height: 21px;
              margin-bottom: 12px;
            }
            .plan-price {
              font-size: 14px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.4);
              line-height: 21px;
              span {
                font-size: 20px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.4);
                line-height: 30px;
              }
            }
          }
        }
      }
      .sub-plan {
        margin-top: 30px;
      }
      .payment {
        margin-top: 65px;
        line-height: 24px;
        img {
          width: 75px;
          height: 18px;
          vertical-align: top;
          margin: 0 12px;
        }
        .title {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          line-height: 24px;
          vertical-align: top;
          .tip {
            font-size: 12px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #e59f3c;
            line-height: 24px;
            vertical-align: top;
          }
        }
        .amount {
          margin-top: 18px;
          text-align: right;
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          line-height: 24px;
          .totalCount {
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #e02020;
            line-height: 30px;
            margin: 0 12px;
          }
          .tip {
            font-size: 12px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ff453a;
            line-height: 18px;
          }
        }
        .buyButton {
          text-align: right;
          margin-top: 20px;
          .buy {
            background: linear-gradient(
              to right,
              rgba(253, 75, 29, 1),
              rgba(252, 176, 69, 1)
            );
            border-radius: 4px;
            color: #fff;
            padding: 10px 32px;
          }
        }
      }
    }
  }
}
.href {
  color: #0075f6;
}
::v-deep .el-dialog__body {
  padding-bottom: 0px;
}
.categoryTips {
  font-size: 12px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
}
</style>